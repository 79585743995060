import React from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

// Redux
import {Provider} from 'react-redux';
import store from './store';

//Components
//import Header from './components/Header';
import Navigation from './components/Navigation';
import Home from './components/Home';
import NewPto from './components/NewPto';
import EditPto from './components/EditPto';
import ViewPto from './components/ViewPto';
import Supervisor from './components/Supervisor';
import Login from './components/Login';
import ManageDepartment from './components/ManageDepartment';
import ManageDepartmentSupervisor from './components/ManageDepartmentSupervisor';
import EmployeePtos from './components/EmployeePtos';
import PrivateRoute from './components/helpers/PrivateRoute';
import PtoCalendar from './components/PtoCalendar';
import PtoYearCalendar from './components/PtoYearCalendar';
import Director from './components/Director';
import NewPtoHr from './components/NewPtoHr';
import NewUser from './components/NewUser';
import ViewUser from './components/ViewUser';
import EditUser from './components/EditUser';
import HistoryUsers from './components/HistoryUsers';
import Footer from './components/Footer';
import UserReports from './components/UserReports';
// Fixed Asset Request
import FixedAssetRequestPanel from './components/FixedAssetRequestPanel';
import NewFixedAsset from './components/NewFixedAsset';
import ViewFixedAssetRequest from './components/ViewFixedAssetRequest';
import EditFixedAssetRequest from './components/EditFixedAssetRequest';

// Personal Requests
import PersonalRequestPanel from './components/PersonalRquestPanel';
import NewPersonalRequest from './components/NewPersonalRequest';
import ViewPersonalRequest from './components/ViewPersonalRequest';
import EditPersonalRequest from './components/EditPersonalRequest';

// Departments
import Departments from './components/Departments';
import NewDepartment from './components/NewDepartment';
import EditDepartment from './components/EditDepartment';
import ViewProfile from './components/ViewProfile';



function App() {

  return (
    <Router>
      <Provider store={store}>
        {/* <Header /> */}
        <Navigation />
        <div style={{minHeight:'100vh'}} className="container">
          <Switch>          
            <Route exact path="/" component = {Login} />
            <Route exact path="/Login" component = {Login} />
              <PrivateRoute>
                <Route exact path="/home" component = {Home} />
                <Route exact path="/pto/new" component = {NewPto} />
                <Route exact path="/pto/new-hr/:emp_uid" component = {NewPtoHr} />
                <Route exact path="/pto/supervisor" component = {Supervisor} />
                <Route exact path="/pto/Director" component = {Director} />
                <Route exact path="/pto/manager" component = {ManageDepartment} />
                <Route exact path="/pto/manager-supervisor" component = {ManageDepartmentSupervisor} />
                <Route exact path="/pto/view/:id" component ={ViewPto} />
                <Route exact path="/pto/edit/:id" component ={EditPto} />
                <Route exact path="/employee/view/:uid" component ={EmployeePtos} />
                <Route exact path="/pto/calendar/:month" component ={PtoCalendar} />
                <Route exact path="/pto/year-calendar" component ={PtoYearCalendar} />
                <Route exact path="/users/new" component ={NewUser} />
                <Route exact path="/users/view/:uid" component ={ViewUser} />
                <Route exact path="/users/profile/:uid" component ={ViewProfile} />
                <Route exact path="/users/edit/:uid" component ={EditUser} />
                <Route exact path="/users/history/:uid" component ={HistoryUsers} />
                <Route exact path="/user-reports" component ={UserReports} />
                <Route exact path="/departments" component ={Departments} />
                <Route exact path="/departments/new" component ={NewDepartment} />
                <Route exact path="/departments/edit/:id" component ={EditDepartment} />
                <Route exact path="/fixed-asset-request/new" component = {NewFixedAsset} />
                <Route exact path="/fixed-asset-request-panel" component = {FixedAssetRequestPanel} />
                <Route exact path="/fixed-asset-request/view/:id" component ={ViewFixedAssetRequest} />
                <Route exact path="/fixed-asset-request/edit/:id" component ={EditFixedAssetRequest} />
                <Route exact path="/personal-request/new" component = {NewPersonalRequest} />
                <Route exact path="/personal-request-panel" component = {PersonalRequestPanel} />
                <Route exact path="/personal-request/view/:id" component ={ViewPersonalRequest} />
                <Route exact path="/personal-request/edit/:id" component ={EditPersonalRequest} />
              </PrivateRoute>
          </Switch>
        </div>
        <Footer />
      </Provider>
    </Router>
  );
}

export default App;
