import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import clienteAxiosHR from '../config/axiosHR';
import moment from 'moment';

const PersonalRequest = ({request, history}) => {

    const supervisor = useSelector(state=>state.userInfo.userInfo.supervisor);
    const userInfo = useSelector(state=> state.userInfo.userInfo);

    return (
        <tr>
            <td>{request.position_title}</td>
            <td>{request.department}</td>
           
            <td>{moment(request.request_date).format('MM-DD-YYYY')}</td>
            <td>{request.status}</td>
            <td className='acciones'>
                <Link
                        to={`/personal-request/view/${request.id}`}
                        className="btn btn-primary m-1"
                    >
                        Details
                </Link>
            {
                request.status === 'New Request' && userInfo.email === 'nubia.osuna@mexilink.com' || userInfo.email === 'anthony.marban@mexilink.com' || userInfo.email === 'ventas.internal18@mexilink.com'
                ?
                <Link
                to={`/personal-request/edit/${request.id}`}
                className="btn btn-success m-1"
                    >
                        Human Resource Approval
                </Link>
                :
                null

            }

            {
                request.status === 'Additional Approval' && userInfo.email === 'nubia.osuna@mexilink.com' || userInfo.email === 'anthony.marban@mexilink.com' || userInfo.email === 'ventas.internal18@mexilink.com'
                ?
                <Link
                to={`/personal-request/edit/${request.id}`}
                className="btn btn-warning m-1"
                    >
                        Finance Approval
                </Link>
                :
                null

            }


                
            </td>
        </tr>
    );
};

export default PersonalRequest;