const exportToCSV = (orders) => {
    // Crear el encabezado del CSV
    const headers = [
        'ID', 'Employee Name', 'Department', 'Reason', 'Status',
        'Request Date', 'Quantity', 'Cost', 'Type', 'Brand', 'Model', 'Asset Number', 'Serial', 'IMEI'
    ];
    
    // Crear filas de datos
    const rows = orders.map(order => {
        const { id, employee_name, department, reason, status, request_date, equipment_requested } = order;

        const oldAssets=[];
        let equipmentFields = [];
        // Parsear los arrays de equipment_requested y old_asset_information
        const equipmentList = JSON.parse(equipment_requested)
            .map((item ,index)=>  {
                // `${item.cantidad} x  ${item.tipo} (Brand: ${item.brand} Model: ${item.model} Asset Number: ${item.assetNumber} Serial: ${item.serial} IMEI: ${item.imei} )` 
                if(index === 0){  
                equipmentFields.push(item.cantidad, item.cost, item.tipo, item.brand, item.model, item.assetNumber, item.serial, item.imei, "\n");
                }else {
                    equipmentFields.push("", "", "", "", "", item.cantidad, item.cost, item.tipo, item.brand, item.model, item.assetNumber, item.serial, item.imei, "\n");
                }
            })
             .join('\n ');



        // if (old_asset_information !== null) {  
        // oldAssets = JSON.parse(old_asset_information).map(item => item).join('; ');
        // }
        return [
            id, employee_name, department, reason, status, request_date, ...equipmentFields
        ];
    });

    // Convertir los datos a formato CSV
    const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");
    
    // Crear un blob y descargarlo
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', 'Orders_Report.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export default exportToCSV;