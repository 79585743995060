import {
    AGREGAR_PERSONAL_REQUEST,
    AGREGAR_PERSONAL_REQUEST_ERROR,
    AGREGAR_PERSONAL_REQUEST_EXITO,
    COMENZAR_DESCARGA_PERSONAL_REQUEST,
    COMENZAR_DESCARGA_PERSONAL_REQUEST_EXITO,
    COMENZAR_DESCARGA_PERSONAL_REQUEST_ERROR,
    OBTENER_DETALLE_PERSONAL_REQUEST,
    OBTENER_DETALLE_PERSONAL_REQUEST_EXITO,
    OBTENER_DETALLE_PERSONAL_REQUEST_ERROR,
    COMENZAR_EDITAR_PERSONAL_REQUEST,
    COMENZAR_EDITAR_PERSONAL_REQUEST_EXITO,
    COMENZAR_EDITAR_PERSONAL_REQUEST_ERROR,
    COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST,
    COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_EXITO,
    COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_ERROR
}
from '../types';

const intialState = {
    personalRequests: [],
    personalSupervisor: [],
    error:false,
    loading: false,
    personalRequest: {}
}

export default function(state=intialState, action) {
    switch(action.type) {
        case AGREGAR_PERSONAL_REQUEST:
            return {
                ...state,
                error: false
            }
        case AGREGAR_PERSONAL_REQUEST_EXITO:
            return {
                ...state,
                personalRequests: [...state.personalRequests, action.payload],
                error: false
            }
        case AGREGAR_PERSONAL_REQUEST_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_DESCARGA_PERSONAL_REQUEST:
            return {
                ...state,
                error:false,
                loading: true,
                personalRequest: {}
            }
        case COMENZAR_DESCARGA_PERSONAL_REQUEST_EXITO:
            return {
                ...state,
                error:false,
                personalRequests: action.payload,
                loading:false,
                personalRequest: {}
            }
        case COMENZAR_DESCARGA_PERSONAL_REQUEST_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
        case COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST:
            return {
                ...state,
                error:false,
                loading: true,
                personalRequest: {}
            }
        case COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_EXITO:
            return {
                ...state,
                error:false,
                personalRequests: action.payload,
                loading:false,
                personalRequest: {}
            }
        case COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
        case OBTENER_DETALLE_PERSONAL_REQUEST:
            return {
                ...state,
                error: false,
                personalRequest: {}
            }
        case OBTENER_DETALLE_PERSONAL_REQUEST_EXITO:
            return {
                ...state,
                error: false,
                personalRequest: action.payload
            }
        case OBTENER_DETALLE_PERSONAL_REQUEST_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_EDITAR_PERSONAL_REQUEST:
            return {
                ...state,
                error: false,
                pto:{}
            }
        case COMENZAR_EDITAR_PERSONAL_REQUEST_EXITO:
            return {
                ...state,
                error: false,
                personalRequests: state.personalRequests.map(request => request.id === action.payload.id ? request = action.payload : request),
                pto:{}
            }
        case COMENZAR_EDITAR_PERSONAL_REQUEST_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}