export const VALIDAR_FORMULARIO = 'VALIDAR_FORMULARIO';
export const VALIDAR_FORMULARIO_EXITO = 'VALIDAR_FORMULARIO_EXITO';
export const VALIDAR_FORMULARIO_ERROR = 'VALIDAR_FORMULARIO_ERROR';

export const OBTENER_INFORMACION_USUARIO = 'OBTENER_INFORMACION_USUARIO';
export const OBTENER_INFORMACION_USUARIO_EXITO = 'OBTENER_INFORMACION_USUARIO_EXITO';
export const OBTENER_INFORMACION_USUARIO_ERROR = 'OBTENER_INFORMACION_USUARIO_ERROR';

export const AGREGAR_TIME_OFF_REQUEST = 'AGREGAR_TIME_OFF_REQUEST';
export const AGREGAR_TIME_OFF_REQUEST_EXITO = 'AGREGAR_TIME_OFF_REQUEST_EXITO';
export const AGREGAR_TIME_OFF_REQUEST_ERROR = 'AGREGAR_TIME_OFF_REQUEST_ERROR';

export const COMENZAR_DESCARGA_PTOS = 'COMENZAR_DESCARGA_PTOS';
export const COMENZAR_DESCARGA_PTOS_EXITO = 'COMENZAR_DESCARGA_PTOS_EXITO';
export const COMENZAR_DESCARGA_PTOS_ERROR = 'COMENZAR_DESCARGA_PTOS_ERROR';

export const OBTENER_PTO_ELIMINAR = 'OBTENER_PTO_ELIMINAR';
export const PTO_ELIMINADO_EXITO = 'PTO_ELIMINADO_EXITO';
export const PTO_ELIMINADO_ERROR = 'PTO_ELIMINADO_ERROR';

export const OBTENER_DETALLE_PTO = 'OBTENER_DETALLE_PTO';
export const OBTENER_DETALLE_PTO_EXITO = 'OBTENER_DETALLE_PTO_EXITO';
export const OBTENER_DETALLE_PTO_ERROR = 'OBTENER_DETALLE_PTO_ERROR';

export const COMENZAR_DESCARGA_PTOS_DEPARTAMENTO = 'COMENZAR_DESCARGA_PTOS_DEPARTAMENTO';
export const COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_EXITO = 'COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_EXITO';
export const COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_ERROR = 'COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_ERROR';

export const OBTENER_PTO_EDITAR = 'OBTENER_PTO_EDITAR';
export const OBTENER_PTO_EDITAR_EXITO = 'OBTENER_PTO_EDITAR_EXITO';
export const OBTENER_PTO_EDITAR_ERROR = 'OBTENER_PTO_EDITAR_ERROR';

export const COMENZAR_APROBACION_PTO = 'COMENZAR_APROBACION_PTO';
export const COMENZAR_APROBACION_PTO_EXITO = 'COMENZAR_APROBACION_PTO_EXITO';
export const COMENZAR_APROBACION_PTO_ERROR = 'COMENZAR_APROBACION_PTO_ERROR';

export const COMENZAR_DENEGAR_PTO = 'COMENZAR_DENEGAR_PTO';
export const COMENZAR_DENEGAR_PTO_EXITO = 'COMENZAR_DENEGAR_PTO_EXITO';
export const COMENZAR_DENEGAR_PTO_ERROR = 'COMENZAR_DENEGAR_PTO_ERROR';

// Descargar PTO Supervisor
export const COMENZAR_DESCARGA_PTOS_SUPERVISOR = 'COMENZAR_DESCARGA_PTOS_SUPERVISOR';
export const COMENZAR_DESCARGA_PTOS_SUPERVISOR_EXITO = 'COMENZAR_DESCARGA_PTOS_SUPERVISOR_EXITO';
export const COMENZAR_DESCARGA_PTOS_SUPERVISOR_ERROR = 'COMENZAR_DESCARGA_PTOS_SUPERVISOR_ERROR';

// Balance de horas
export const OBTENER_BALANCE_HORAS = 'OBTENER_BALANCE_HORAS';
export const OBTENER_BALANCE_HORAS_EXITO = 'OBTENER_BALANCE_HORAS_EXITO';
export const OBTENER_BALANCE_HORAS_ERROR = 'OBTENER_BALANCE_HORAS_ERROR';

// Actualizar Balance Horas
export const COMENZAR_AJUSTE_HORAS = 'COMENZAR_AJUSTE_HORAS';
export const COMENZAR_AJUSTE_HORAS_EXITO = 'COMENZAR_AJUSTE_HORAS_EXITO';
export const COMENZAR_AJUSTE_HORAS_ERROR = 'COMENZAR_AJUSTE_HORAS_ERROR';

// OBtener Usuario Balance Horas
export const OBTENER_USUARIO_AJUSTE = 'OBTENER_USUARIO_AJUSTE';
export const OBTENER_USUARIO_AJUSTE_EXITO = 'OBTENER_USUARIO_AJUSTE_EXITO';
export const OBTENER_USUARIO_AJUSTE_ERROR = 'OBTENER_USUARIO_AJUSTE_ERROR';

// Obtener Departamentes
export const OBTENER_DEPARTAMENTOS = 'OBTENER_DEPARTAMENTOS';
export const OBTENER_DEPARTAMENTOS_EXITO = 'OBTENER_DEPARTAMENTOS_EXITO';
export const OBTENER_DEPARTAMENTOS_ERROR = 'OBTENER_DEPARTAMENTOS_ERROR';

// Obtener Employees
export const OBTENER_EMPLEADOS_DEPARTAMENTO = 'OBTENER_EMPLEADOS_DEPARTAMENTO';
export const OBTENER_EMPLEADOS_DEPARTAMENTO_EXITO = 'OBTENER_EMPLEADOS_DEPARTAMENTO_EXITO';
export const OBTENER_EMPLEADOS_DEPARTAMENTO_ERROR = 'OBTENER_EMPLEADOS_DEPARTAMENTO_ERROR';

// Actualizar horas mensualemente basado en hire Date
export const COMENZAR_AJUSTE_MENSUAL_HORAS = 'COMENZAR_AJUSTE_MENSUAL_HORAS';
export const COMENZAR_AJUSTE_MENSUAL_HORAS_EXITO = 'COMENZAR_AJUSTE_MENSUAL_HORAS_EXITO';
export const COMENZAR_AJUSTE_MENSUAL_HORAS_ERROR = 'COMENZAR_AJUSTE_MENSUAL_HORAS_ERROR';

// Actualizar PTOS
export const COMENZAR_EDITAR_PTO = 'COMENZAR_EDITAR_PTO';
export const COMENZAR_EDITAR_PTO_EXITO = 'COMENZAR_EDITAR_PTO_EXITO';
export const COMENZAR_EDITAR_PTO_ERROR = 'COMENZAR_EDITAR_PTO_ERROR';

// Listar Ptos Directores
export const COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR = 'COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR';
export const COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_EXITO = 'COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_EXITO';
export const COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_ERROR = 'COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_ERROR';

// Crear Usurios
export const CREAR_USUARIO = 'CREAR_USUARIO';
export const CREAR_USUARIO_EXITO = 'CREAR_USUARIO_EXITO';
export const CREAR_USUARIO_ERROR = 'CREAR_USUARIO_ERROR';

// editar Usurios
export const EDITAR_USUARIO = 'EDITAR_USUARIO';
export const EDITAR_USUARIO_EXITO = 'EDITAR_USUARIO_EXITO';
export const EDITAR_USUARIO_ERROR = 'EDITAR_USUARIO_ERROR';

// Sick Days
export const OBTENER_SICKDAYS = 'OBTENER_SICKDAYS';
export const OBTENER_SICKDAYS_EXITO = 'OBTENER_SICKDAYS_EXITO';
export const OBTENER_SICKDAYS_ERROR = 'OBTENER_SICKDAYS_ERROR';

// Comenzar Ajustes CARRY OVER
export const AJUSTAR_CARRY_OVER = 'AJUSTAR_CARRY_OVER';
export const AJUSTAR_CARRY_OVER_EXITO = 'AJUSTAR_CARRY_OVER_EXITO';
export const AJUSTAR_CARRY_OVER_ERROR = 'AJUSTAR_CARRY_OVER_ERROR';

// OBTENER COMPANY
export const OBTENER_COMPANIES = 'OBTENER_COMPANIES';
export const OBTENER_COMPANIES_EXITO = 'OBTENER_COMPANIES_EXITO';
export const OBTENER_COMPANIES_ERROR = 'OBTENER_COMPANIES_ERROR';

// OBTENER TERRITORY
export const OBTENER_TERRITORY = 'OBTENER_TERRITORY';
export const OBTENER_TERRITORY_EXITO = 'OBTENER_TERRITORY_EXITO';
export const OBTENER_TERRITORY_ERROR = 'OBTENER_TERRITORY_ERROR';

// OBTENER EMPLOYEES MANAGER
export const OBTENER_EMPLOYEES_MANAGER = 'OBTENER_EMPLOYEES_MANAGER';
export const OBTENER_EMPLOYEES_MANAGER_EXITO = 'OBTENER_EMPLOYEES_MANAGER_EXITO';
export const OBTENER_EMPLOYEES_MANAGER_ERROR = 'OBTENER_EMPLOYEES_MANAGER_ERROR';

// OBTENER HISTORICO
export const OBTENER_HISTORICO = 'OBTENER_HISTORICO';
export const OBTENER_HISTORICO_EXITO = 'OBTENER_HISTORICO_EXITO';
export const OBTENER_HISTORICO_ERROR = 'OBTENER_HISTORICO_ERROR';

// EDITAR DEPARTAMENTOS
export const COMENZAR_EDITAR_DEPARTMENT  = 'COMENZAR_EDITAR_DEPARTMENT';
export const COMENZAR_EDITAR_DEPARTMENT_EXITO  = 'COMENZAR_EDITAR_DEPARTMENT_EXITO';
export const COMENZAR_EDITAR_DEPARTMENT_ERROR  = 'COMENZAR_EDITAR_DEPARTMENT_ERROR';

// EDITAR DEPARTAMENTOS
export const COMENZAR_CREAR_DEPARTMENT  = 'COMENZAR_CREAR_DEPARTMENT';
export const COMENZAR_CREAR_DEPARTMENT_EXITO  = 'COMENZAR_CREAR_DEPARTMENT_EXITO';
export const COMENZAR_CREAR_DEPARTMENT_ERROR  = 'COMENZAR_CREAR_DEPARTMENT_ERROR';


// OBTENER ESTADOS ACTIVOS
export const OBTENER_ESTADOS_ACTIVOS = 'OBTENER_ESTADOS_ACTIVOS';
export const OBTENER_ESTADOS_ACTIVOS_EXITO = 'OBTENER_ESTADOS_ACTIVOS_EXITO';
export const OBTENER_ESTADOS_ACTIVOS_ERROR = 'OBTENER_ESTADOS_ACTIVOS_ERROR';

// editar Usurios
export const EDITAR_PROFILE = 'EDITAR_PROFILE';
export const EDITAR_PROFILE_EXITO = 'EDITAR_PROFILE_EXITO';
export const EDITAR_PROFILE_ERROR = 'EDITAR_PROFILE_ERROR';

// Aregar FIXED ASSET REQUEST TYPES

export const AGREGAR_FIXED_ASSET_REQUEST = 'AGREGAR_FIXED_ASSET_REQUEST';
export const AGREGAR_FIXED_ASSET_REQUEST_EXITO = 'AGREGAR_FIXED_ASSET_REQUEST_EXITO';
export const AGREGAR_FIXED_ASSET_REQUEST_ERROR = 'AGREGAR_FIXED_ASSET_REQUEST_ERROR';

export const COMENZAR_DESCARGA_FIXED_ASSET_REQUEST = 'COMENZAR_DESCARGA_FIXED_ASSET_REQUEST';
export const COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_EXITO = 'COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_EXITO';
export const COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_ERROR = 'COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_ERROR';

export const OBTENER_DETALLE_FIXED_ASSET_REQUEST = 'OBTENER_DETALLE_FIXED_ASSET_REQUEST';
export const OBTENER_DETALLE_FIXED_ASSET_REQUEST_EXITO = 'OBTENER_DETALLE_FIXED_ASSET_REQUEST_EXITO';
export const OBTENER_DETALLE_FIXED_ASSET_REQUEST_ERROR = 'OBTENER_DETALLE_FIXED_ASSET_REQUEST_ERROR';

export const COMENZAR_EDITAR_FIXED_REQUEST = 'COMENZAR_EDITAR_FIXED_REQUEST';
export const COMENZAR_EDITAR_FIXED_REQUEST_EXITO = 'COMENZAR_EDITAR_FIXED_REQUEST_EXITO';
export const COMENZAR_EDITAR_FIXED_REQUEST_ERROR = 'COMENZAR_EDITAR_FIXED_REQUEST_ERROR';

export const COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST = 'COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST';
export const COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_EXITO = 'COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_EXITO';
export const COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_ERROR = 'COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_ERROR';

// Aregar PERSONAL REQUEST TYPES

export const AGREGAR_PERSONAL_REQUEST = 'AGREGAR_PERSONAL_REQUEST';
export const AGREGAR_PERSONAL_REQUEST_EXITO = 'AGREGAR_PERSONAL_REQUEST_EXITO';
export const AGREGAR_PERSONAL_REQUEST_ERROR = 'AGREGAR_PERSONAL_REQUEST_ERROR';

export const COMENZAR_DESCARGA_PERSONAL_REQUEST = 'COMENZAR_DESCARGA_PERSONAL_REQUEST';
export const COMENZAR_DESCARGA_PERSONAL_REQUEST_EXITO = 'COMENZAR_DESCARGA_PERSONAL_REQUEST_EXITO';
export const COMENZAR_DESCARGA_PERSONAL_REQUEST_ERROR = 'COMENZAR_DESCARGA_PERSONAL_REQUEST_ERROR';

export const OBTENER_DETALLE_PERSONAL_REQUEST = 'OBTENER_DETALLE_PERSONAL_REQUEST';
export const OBTENER_DETALLE_PERSONAL_REQUEST_EXITO = 'OBTENER_DETALLE_PERSONAL_REQUEST_EXITO';
export const OBTENER_DETALLE_PERSONAL_REQUEST_ERROR = 'OBTENER_DETALLE_PERSONAL_REQUEST_ERROR';

export const COMENZAR_EDITAR_PERSONAL_REQUEST = 'COMENZAR_EDITAR_PERSONAL_REQUEST';
export const COMENZAR_EDITAR_PERSONAL_REQUEST_EXITO = 'COMENZAR_EDITAR_PERSONAL_REQUEST_EXITO';
export const COMENZAR_EDITAR_PERSONAL_REQUEST_ERROR = 'COMENZAR_EDITAR_PERSONAL_REQUEST_ERROR';

export const COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST = 'COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST';
export const COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_EXITO = 'COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_EXITO';
export const COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_ERROR = 'COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_ERROR';