import {
    AGREGAR_PERSONAL_REQUEST,
    AGREGAR_PERSONAL_REQUEST_EXITO,
    AGREGAR_PERSONAL_REQUEST_ERROR,
    COMENZAR_DESCARGA_PERSONAL_REQUEST,
    COMENZAR_DESCARGA_PERSONAL_REQUEST_EXITO,
    COMENZAR_DESCARGA_PERSONAL_REQUEST_ERROR,
    OBTENER_DETALLE_PERSONAL_REQUEST,
    OBTENER_DETALLE_PERSONAL_REQUEST_EXITO,
    OBTENER_DETALLE_PERSONAL_REQUEST_ERROR,
    COMENZAR_EDITAR_PERSONAL_REQUEST,
    COMENZAR_EDITAR_PERSONAL_REQUEST_EXITO,
    COMENZAR_EDITAR_PERSONAL_REQUEST_ERROR,
    COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST,
    COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_EXITO,
    COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_ERROR
}
from '../types';

import clienteAxiosHR from '../config/axiosHR';

// Create new Fixed Asset Request
export function createNewPersonalRequestAction(request) {
    return async (dispatch) => {
        dispatch( newPersonalRequest());
        // Insertar en la API
        await clienteAxiosHR.post('/create-personal-request', request)
        .then(respuesta => {
            //console.log(respuesta.data.data.insertId)
            request["id"] = respuesta.data.data.insertId;
            //console.log(pto);
            //Si se inserta correctamente
            dispatch(newPersonalRequestSuccess(request));

            //dispatch(getPTOListAction());
        })
        .catch(error => {
            // console.log(error);

            // Si hay un error
            dispatch(newPersonalRequestError());
        })
    
    }
}


export const newPersonalRequest = () => ({
    type: AGREGAR_PERSONAL_REQUEST
});

export const newPersonalRequestSuccess = (request) => ({
    type: AGREGAR_PERSONAL_REQUEST_EXITO,
    payload: request
});

export const newPersonalRequestError = () => ({
    type: AGREGAR_PERSONAL_REQUEST_ERROR
});


// Retrieve Fixed Asset Request

export function getAllPersonalsRequestList () {
    return async (dispatch) => {
        dispatch(startGetAllPersonalRequestList());

        await clienteAxiosHR.get(`/personal-request-list-all`)
        .then((respuesta) =>{
            // const department = respuesta.data.data; 
           // console.log(respuesta)
             dispatch(getAllPersonalRequestListSuccess(respuesta.data.data));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getAllPersonalRequestListError());
        })
    }
}

export const startGetAllPersonalRequestList = () => ({
    type: COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST
});

export const getAllPersonalRequestListSuccess = (personalRequests) => ({
    type: COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_EXITO,
    payload: personalRequests
});

export const getAllPersonalRequestListError = () => ({
    type: COMENZAR_DESCARGA_TOTAL_PERSONAL_REQUEST_ERROR
});

export function getPersonalsRequestList (department, territory, dateReport, fromDate, toDate) {
    return async (dispatch) => {
        dispatch(startGetPersonalRequestList());

        let data = {};
        if(dateReport === 'all') {
        //Consultar la API
        data = {
            department: department,
            territory:territory,
            dateReport: dateReport
        } 
    } else {
        data = {
            department: department,
            territory:territory,
            dateReport: dateReport,
            fromDate: fromDate,
            toDate: toDate
        }
    }

        console.log(data);

        await clienteAxiosHR.post(`/personal-request-list`, data)
        .then((respuesta) =>{
            // const department = respuesta.data.data; 
           // console.log(respuesta)
             dispatch(getPersonalRequestListSuccess(respuesta.data.data));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getPersonalRequestListError());
        })
    }
}

export const startGetPersonalRequestList = () => ({
    type: COMENZAR_DESCARGA_PERSONAL_REQUEST
});

export const getPersonalRequestListSuccess = (ptoListDepartment) => ({
    type: COMENZAR_DESCARGA_PERSONAL_REQUEST_EXITO,
    payload: ptoListDepartment
});

export const getPersonalRequestListError = () => ({
    type: COMENZAR_DESCARGA_PERSONAL_REQUEST_ERROR
});


// Mostrar detalle PTO

export function getPersonalRequestDetail (id) {
    return async (dispatch) => {
        dispatch(startPersonalRequestDetail());

        //Consultar la API
        await clienteAxiosHR.get(`/personal-request/view/${id}`)
        .then((respuesta) =>{
            const request = respuesta.data.data; 
            console.log(request);
            dispatch(getPersonalRequestSuccess(request));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getPersonalRequestError());
        })
    }
}

export const startPersonalRequestDetail = () =>({
    type: OBTENER_DETALLE_PERSONAL_REQUEST
})

export const getPersonalRequestSuccess = (request) =>({
    type: OBTENER_DETALLE_PERSONAL_REQUEST_EXITO,
    payload: request
})

export const getPersonalRequestError = () =>({
    type: OBTENER_DETALLE_PERSONAL_REQUEST_ERROR
})


// Edit Fixed Asset request Action
export function editPersonalRequestAction(request) {
    return async (dispatch) => {
        dispatch( editPersonalRequest());
        // Insertar en la API
        await clienteAxiosHR.put('/update-personal-request', request)
        .then(respuesta => {

            console.log(request, 'console log de la respuesta');
            //Si se actualiza correctamente
            dispatch(editPersonalRequestSuccess(request));

            //dispatch(getPTOListAction());
        })
        .catch(error => {
            // console.log(error);

            // Si hay un error
            dispatch(editPersonalRequestError());
        })
    
    }
}


export const editPersonalRequest = () => ({
    type: COMENZAR_EDITAR_PERSONAL_REQUEST
});

export const editPersonalRequestSuccess = (request) => ({
    type: COMENZAR_EDITAR_PERSONAL_REQUEST_EXITO,
    payload: request
});

export const editPersonalRequestError = () => ({
    type: COMENZAR_EDITAR_PERSONAL_REQUEST_ERROR
});