import React, {useState, useEffect} from 'react';
//import ReactDom from 'react-dom';

// Redux
import { useDispatch } from 'react-redux';
import {crearUsuario} from '../actions/authenticateActions';
import {obtenerDepartamentos} from '../actions/manageActions';
// library

import { useSelector } from 'react-redux';
import moment from 'moment-business-days';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
 
import "react-datepicker/dist/react-datepicker.css";
import axiosHr from '../config/axiosHR';

const NewUser = ({history}) => {

    // var holidaysArray = ['09-02-2019', '11-28-2019', '11-29-2019', '12-24-2019', '12-25-2019', '01-01-2020', '04-10-2020', '05-27-2020', '07-04-2020', '09-02-2020', '11-28-2020', '11-29-2020', '12-24-2020', '12-25-2020', '01-01-2021']

    // var excludedDatesArray = [new Date("2019-09-02T12:00:00-00:00"), new Date("2019-11-28T12:00:00-00:00"), new Date("2019-11-29T12:00:00-00:00"), new Date("2019-12-24T12:00:00-00:00"), new Date("2019-12-25T12:00:00-00:00"), new Date("2019-01-01T12:00:00-00:00"), new Date("2020-04-10T12:00:00-00:00"), new Date("2020-05-27T12:00:00-00:00"),new Date("2020-07-04T12:00:00-00:00"), new Date("2020-09-02T12:00:00-00:00"), new Date("2020-11-28T12:00:00-00:00"), new Date("2020-11-29T12:00:00-00:00"), new Date("2020-12-24T12:00:00-00:00"), new Date("2020-12-25T12:00:00-00:00"), new Date("2020-01-01T12:00:00-00:00"), new Date("2021-01-01T12:00:00-00:00"), ]
   
    // obtener departamentos
    const departments = useSelector(state=>state.manager.departments);
    const companyManager = useSelector(state=>state.userInfo.userInfo.company);

    


    const filterDepartment  = (array, query) => {
        return array.filter(function(el) {
            // console.log(el)
            let departmentElement = el.id
            return departmentElement === Number(query)
        })
      }

      const filterDepartmentbyName  = (array, query) => {
        return array.filter(function(el) {
            let departmentElement = el.department
            return departmentElement.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }

    // set working days
    moment.updateLocale('us', {
       // workingWeekdays: [1, 2, 3, 4, 5],
      //  holidays: holidaysArray,
      //  holidayFormat: 'MM-DD-YYYY'

     });


    // state
   // const [imageProfile, saveImageProfile] = useState('');
    const [chooseText, saveChooseText] = useState('Upload Profile Image');
    const [imgSrc, saveImgSrc] = useState('./../avatar-placeholder.png');
    const [imgPath, saveImgPath] = useState('');
    const [employeeName, saveEmployeeName] = useState('');
    const [employeeLastname, saveEmployeeLastname] = useState('');
    const [username, saveUserName] = useState('');
    const [company, saveCompany] = useState('');
    const [department, saveDepartment] = useState('');
    const [supervisor, saveSupervisor] = useState(false);
    const [email, saveEmail] = useState('');
    const [emailSupervisor, saveEmailSupervisor] = useState('');
    const [uid, saveUid] = useState('');
    const [token, saveToken] = useState('');
    const [hireDate, saveHireDate] = useState('');
    const [sickDays, saveSickDays] = useState('');
    const [accruedTimeSick, saveAccruedTimeSick] = useState('');
    const [territory, saveTerritory] = useState('');
    const [travelBenefits, saveTravelBenefits] = useState(false);
    const [idDepartment, saveIdDepartment] = useState('');
    const [statesTerritory, saveStatesTerritory] = useState ([]);
    const [gender, saveGender] = useState('');
    const [parenthood, saveParenthood] = useState(false);
    const [workplace, saveWorkplace] = useState('');
    const [phone, savePhone] = useState('');
    const [birthplace, saveBirthPlace] = useState('');
    const [birthdate, saveBirthDate] = useState('');
    const[jobTitle, saveJobTitle] = useState('');

    useEffect( () => {
        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();

        axiosHr.get(`/active-states/${companyManager}`).then(resp => {
            saveStatesTerritory(resp.data.data)
           // console.log(resp);
        })
        .catch(error => console.log(error));
        
        
    }, [])
    


    const isWeekday = date => {
        const day = moment(date).day();
        return day !== 0 && day !== 6;
      };

    const handleChangeInput = (event) => {
        const {value} = event.target;
        let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

        if(regex.test(value)) {
           // console.log(value);
            saveEmployeeName(value);
        } else {
            console.log("Es un caracter no válido")
        }
    }

    const handleChangeLastName = (event) => {
        const {value} = event.target;
        let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

        if(regex.test(value)) {
           // console.log(value);
            saveEmployeeLastname(value);
        } else {
            console.log("Es un caracter no válido")
        }
    }

    const handleChangeJobTitle = (event) => {
        const {value} = event.target;
        let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

        if(regex.test(value)) {
           // console.log(value);
            saveJobTitle(value);
        } else {
            console.log("Es un caracter no válido")
        }
    }

    const handleCheckTravelBenefits = (value) => {
        saveTravelBenefits(!travelBenefits);
    }

    const handleDepartmentSelect = (value) => {

        let departmentFilter = filterDepartment(departments, value)
        console.log(departmentFilter, value)
        saveDepartment(departmentFilter[0].department)
        
        if(!supervisor) {
            saveEmailSupervisor(departmentFilter[0].email_director);
            saveIdDepartment(departmentFilter[0].id)
        }else {
            saveEmailSupervisor(departmentFilter[0].report_to);
            saveIdDepartment(departmentFilter[0].id)
        }
    }

    const handleCheckSupervisor = (value) => {
        console.log(department, supervisor, value);
        saveSupervisor(!supervisor);
        if(!supervisor) {
            let departmentFilter = filterDepartmentbyName(departments, value);
            saveEmailSupervisor(departmentFilter[0].report_to);           
        }else {
            let departmentFilter = filterDepartmentbyName(departments, value)
            saveEmailSupervisor(departmentFilter[0].email_director);
        }  
          
    }

    const handleCheckParenthood = (value) => {
        saveParenthood(!parenthood)
    }

   const fileChangedHandler = (event) => {
        const file = event.target.files[0];
       // saveImageProfile(file);
        saveChooseText(file.name);
        saveImgSrc(URL.createObjectURL(file));
      //  saveImgPath(`./images-directory/${file.name}`);
      fileUpload(file);
      }

    const fileUpload = (file) => {

       var reader = new FileReader(); // Creating reader instance from FileReader() API

        reader.addEventListener("load", function () { // Setting up base64 URL on image
           saveImgPath(reader.result);
        }, false);

        reader.readAsDataURL(file); // Converting file into data URL
      }
    const handleTerritory = (territoryInput) => {
        saveTerritory(territoryInput);

        let sick_days_plan = statesTerritory.filter((ele => {
            let name = ele.name
            console.log(name === territoryInput)
            return name === territoryInput;
        } ))
       // console.log(sick_days_plan[0].sick_days_plan);

        saveAccruedTimeSick(sick_days_plan[0].sick_days_plan);
    }

    // dispatch actions
    const dispatch = useDispatch();
     const createUser = (user) => dispatch (crearUsuario(user));

    
    // Add new PTO
    const submitNewUser = e => {
      e.preventDefault();
    //  validarFormulario();
      // Preguntar al usuario
      Swal.fire({
        title: 'Request Summary',
        text: "This is your summary request, are you sure to submit ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        html: '<div>'+
                    '<p> Name : ' + employeeName + ' '+employeeLastname+'</p>' +
                 '</div>'
      }).then((result) => {
        if (result.value) {
          Swal.fire(
            'Saved',
            'Your request has been sent successfully',
            'success'
          )
       
           const formatHireDate = moment(hireDate).format('YYYY-MM-DD');
           const formatBirthDate = moment(birthdate).format('YYYY-MM-DD');
            createUser({
                imgPath,
                employeeName,
                employeeLastname,
                username,
                company,
                department,
                supervisor,
                email,
                emailSupervisor,
                uid,
                token,
                sickDays,
                formatHireDate,
                territory,
                travelBenefits,
                accruedTimeSick,
                idDepartment,
                gender,
                parenthood,
                workplace,
                formatBirthDate,
                birthplace,
                jobTitle,
                phone
            });

            let data = {
                uid : uid,
                hours_before_mov:0,
                hours_after_mov:0,
                hours_mov: sickDays,
                carry_over_before_mov:0,
                carry_over_after_mov:0,
                carry_over_sick_time_before:0,
                carry_over_sick_time_after:0,
                observations: `Create New User Sick Days (hours: ${sickDays})`,
                type_mov: 'New User',
                date_mov: moment().format('YYYY-MM-DD'),
                mov_increase_date: moment().format('YYYY-MM-DD'),
                mov_yearly_increase_date: moment().format('YYYY-MM-DD'),
                increase_date_before_mov: moment().format('YYYY-MM-DD'),
                yearly_increase_date_before_mov: moment().format('YYYY-MM-DD')
            }
            axiosHr.post('/hour-movement-log', data)
            .then(respuesta => {
                console.log(respuesta)
            })
            .catch(error => {
            console.log(error)
            });

            //redirect
            history.push('/home');
        }
    })
      
      
    }

    return (
        <div className="row justify-content-center mt-5">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 font-weight-bold ">Create New User</h2>
                        <form onSubmit={submitNewUser}>
                            <div className="form-group">
                                <div className="col-sm-12 col-md-4 card-avatar-container mb-3">
                                    {/* Change avatar dinamically */}
                                    <img src={imgSrc} className="card-img" alt="avatar" />
                                </div>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="customFile" onChange={fileChangedHandler}/>
                                    <label className="custom-file-label" htmlFor="customFile">{chooseText}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Employee Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Name"
                                    value={employeeName}
                                    onChange={handleChangeInput}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Last Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Last Name"
                                    value={employeeLastname}
                                    onChange={handleChangeLastName}
                                    required
                                />
                            </div>
                            
                            <div className="form-group">
                                <label>Job Title</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Job Title"
                                    value={jobTitle}
                                    onChange={handleChangeJobTitle}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Gender</label>
                                <select className="custom-select"
                                 onChange={e=>saveGender(e.target.value)}
                                 required
                                >
                                    <option value="">Select gender...</option>
                                    <option value="F">Female</option>
                                    <option value="M">Male</option>                                        
                                </select>
                            </div>

                            <div className="form-group">
                                <p><label>Parenthood</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch3" onChange={e => handleCheckParenthood(e.target.value) } 
                                    checked= {parenthood}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch3">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Work Place</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Work Place"
                                    value={workplace}
                                    onChange={e=>saveWorkplace(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Birth Date:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDayBeginning(e.target.value)}
                                        required
                                        ref = {dayBeginingRef}
                                        /> */}
                                        <DatePicker
                                        className="datePicker userdatepicker"
                                        todayButton="Today"
                                        selected={birthdate}
                                        onChange={date => saveBirthDate(date)}
                                        placeholderText="Select birth date"
                                       // minDate={new Date()}
                                        />

                                    </div>
                                </div>

                            <div className="form-group">
                                <label>Birth Place</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Birth Place"
                                    value={birthplace}
                                    onChange={e=>saveBirthPlace(e.target.value)}
                                    required
                                />
                            </div>


                            <div className="form-group">
                                <label>User Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Username"
                                    value={username}
                                    onChange={e=>saveUserName(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Company</label>
                                <select className="custom-select"
                                 onChange={e=>saveCompany(e.target.value)}
                                 required
                                >
                                    <option value="">Select company...</option>
                                    <option value="CSI">CSI</option>
                                    <option value="Hit Sales & Marketing">Hit Sales & Marketing</option>
                                    <option value="Mexilink">Mexilink</option>
                                    
                                    
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Department</label>
                                <select className="custom-select"
                                 onChange={e=>handleDepartmentSelect(e.target.value)}
                                 required
                                >
                                    <option value="">Select department...</option>
                                   
                                    {departments.map((department, index) =>(
                                                   <option key={index} value={department.id}>{department.department}</option>
                                                ))}
                                </select>
                            </div>

                            <div className="form-group">
                                <p><label>Supervisor</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch2" onChange={e => handleCheckSupervisor(department) } 
                                    checked= {supervisor}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch2">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Email</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email"
                                    onChange={ e => saveEmail(e.target.value) }
                                    required
                                />
                            </div>

                            <div className="form-group ">
                                <label>Email Supervisor</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email Supervisor"
                                    onChange={ e => saveEmailSupervisor(e.target.value) }
                                    value= {emailSupervisor}
                                    required
                                />
                            </div>
                            <div className='form-group'>
                            <label>Phone Number:</label>
                                <PhoneInput
                                country="US"
                                defaultCountry='US'
                                value={phone}
                                onChange={savePhone} />
                            </div>

                            <div className="form-group">
                                <label>MXL Portal User ID</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="UID"
                                    onChange={ e => saveUid(e.target.value) }
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>MXL Portal Token</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Token"
                                    onChange={ e => saveToken(e.target.value) }
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Sick Days (Hours)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Sick Days"
                                    onChange={ e => saveSickDays(e.target.value) }
                                    required
                                />
                            </div>

                            <div className="row container justify-content-between">
                                
                                
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Hire Date:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDayBeginning(e.target.value)}
                                        required
                                        ref = {dayBeginingRef}
                                        /> */}
                                        <DatePicker
                                        className="datePicker userdatepicker"
                                        todayButton="Today"
                                        selected={hireDate}
                                        onChange={date => saveHireDate(date)}
                                        filterDate={isWeekday}
                                        placeholderText="Select a weekday"
                                       // minDate={new Date()}
                                      //  excludeDates={excludedDatesArray}
                                        />

                                    </div>
                                </div>
                                
                            </div>

                            <div className="form-group">
                                <label>Territory</label>
                                <select className="custom-select"
                                 onChange={e=>handleTerritory(e.target.value)}
                                 required
                                >
                                    <option value="">Select Territory...</option>

                                    {
                                    statesTerritory.map((state, index) =>(
                                        <option key={index} value={state.name}>{state.name}</option>
                                    ))
                                    }
                                </select>
                            </div>
                            
                            <div className="form-group">
                                <label>Accrued Time Off (Sick Days Plan)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Sick Days Plan"
                                    value = {accruedTimeSick}
                                    onChange={ e => saveAccruedTimeSick(e.target.value) }
                                    required
                                />
                            </div>            

                            <div className="form-group">
                                <p><label>Travel Benefits</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={e => handleCheckTravelBenefits(e.target.value) } 
                                    checked= {travelBenefits}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch1">Yes / No</label>
                                </div>
                            </div>
                            

                            <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4">Submit</button>
                        </form>
        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewUser;