import React, {useEffect, useState, useRef, Fragment} from 'react';
// Redux
import {useDispatch, useSelector} from 'react-redux';
import { getPersonalRequestDetail } from '../actions/personalrequestActions';
import moment from 'moment/moment';
import DatePicker from "react-datepicker";
import SignatureCanvas from 'react-signature-canvas';
import {obtenerDepartamentos} from '../actions/manageActions';
import { validarFormularioAction, validacionExito, validacionError } from '../actions/validacionActions';


const ViewPersonalRequest = ({match}) => {

    // Dispatch para ejecutar la acción principal
    const dispatch = useDispatch();

     // obtener el ID a editar
     const { id } = match.params;

     const personalRequest = useSelector(state => state.personalRequest.personalRequest);

	 // get data from state
	 const error = useSelector((state) => state.error.error);
	 //acceder al state
	 const userInfo = useSelector(state=>state.userInfo);
	
	 var excludedDatesArray = [new Date("2021-02-04T12:00:00-00:00"), new Date("2021-05-31T12:00:00-00:00"), new Date("2021-07-05T12:00:00-00:00"), new Date("2021-09-06T12:00:00-00:00"), new Date("2021-11-25T12:00:00-00:00"), new Date("2021-11-26T12:00:00-00:00"), new Date("2021-12-23T12:00:00-00:00"), new Date("2021-12-24T12:00:00-00:00"),new Date("2022-01-01T12:00:00-00:00"), new Date("2021-01-01T12:00:00-00:00"), new Date("2022-04-15T12:00:00-00:00"), new Date("2022-05-30T12:00:00-00:00"), new Date("2022-07-04T12:00:00-00:00"), new Date("2022-09-05T12:00:00-00:00"), new Date("2022-11-24T12:00:00-00:00"), new Date("2022-11-25T12:00:00-00:00"), new Date("2022-12-23T12:00:00-00:00"), new Date("2022-12-26T12:00:00-00:00"), new Date("2023-01-02T12:00:00-00:00"), new Date("2023-04-07T12:00:00-00:00"), new Date("2023-05-29T12:00:00-00:00"), new Date("2023-07-04T12:00:00-00:00"), new Date("2023-09-04T12:00:00-00:00"), new Date("2023-11-23T12:00:00-00:00"), new Date("2023-11-24T12:00:00-00:00"), new Date("2023-12-22T12:00:00-00:00"), new Date("2023-12-25T12:00:00-00:00"), new Date("2024-01-01T12:00:00-00:00"), new Date("2024-03-29T12:00:00-00:00"), new Date("2024-05-27T12:00:00-00:00"), new Date("2024-07-04T12:00:00-00:00"), new Date("2024-09-02T12:00:00-00:00"), new Date("2024-11-28T12:00:00-00:00"), new Date("2024-11-29T12:00:00-00:00"), new Date("2024-12-24T12:00:00-00:00"), new Date("2024-12-25T12:00:00-00:00"), new Date("2025-01-01T12:00:00-00:00")]

     // state
    const [dateSubmitted, saveDateSubmitted] = useState('');
    const [positionTitle, savePositionTitle] = useState('');
    const [department, saveDepartment] = useState('');
    const [costCenter, saveCostCenter] = useState('');
    const [status, saveStatus] = useState('');
   
	const [requestorName, saveRequestorName] = useState('');
	const [requestorTitle, saveRequestorTitle] = useState('');
    const [signatureEmployee, saveSignatureEmployee] = useState('');
    const [dateSignEmployee, saveDateSignEmployee] = useState('');

	const [requestedStartDate, saveRequestedStartDate] = useState('');
	const [hiringManager, saveHiringManager] = useState('');
	const [contactNumber, saveContactNumber] = useState('');
	
	//const [employmentClassification, saveEmploymentClassification] = useState('');
	const [exempt, saveExempt] = useState(false);
	const [nonExempt, saveNonExempt] = useState(false);
	const [fullTime, saveFullTime] = useState(false);
	const [partTime, savePartTime] = useState(false);
	const [other, saveOther] = useState(false);
	const [otherText, saveOtherText] = useState('');

	const [shiftFrom, saveShiftFrom] = useState('');
	const [shiftTo, saveShiftTo] = useState('');
	const [monday, saveMonday] = useState(false);
	const [tuesday, saveTuesday] = useState(false);
	const [wednesday, saveWednesday] = useState(false);
	const [thursday, saveThursday] = useState(false);
	const [friday, saveFriday] = useState(false);
	const [saturday, saveSaturday] = useState(false);
	const [sunday, saveSunday] = useState(false);

	const [additionalCommentWorkSchedule, saveadditionalCommentWorkSchedule] = useState('');
	const [newPosition, saveNewPosition] = useState(false);
	const [positionExist, savePositionExist] = useState("yes");
	const [budgeted, saveBudgeted] = useState("yes");
	const [existingPositionReplacement, saveExistingPositionReplacement] = useState('');
	const [dutiesChanged, saveDutiesChanged] = useState("yes");

	const [positionInformation, savePositionInformation] = useState('');
	const [dailyActivities, saveDailyActivities] = useState('');
	const [measureProductivity, saveMeasureProductivity] = useState('');
	const [financialImpact, saveFinancialImpact] = useState('');
	const [positionFit, savePositionFit] = useState('');

	const [objectives, saveObjectives] = useState('');
	const [contingencies, saveContingencies] = useState('');

	const [dateFilled, saveDateFilled] = useState('');
	const [positionFilledBy, savePositionFilledBy] = useState('');
	const [startDate, saveStartDate] = useState('');
	const [salaryOffered, saveSalaryOffered] = useState('');
	const [recruitmentSource, saveRecruitmentSource] = useState('');

	const [HrApprover, saveHrApprover] = useState('');
	const [HrApproverTitle, saveHrApproverTitle] = useState('');
    const [signatureHRApprover, saveSignatureHRApprover] = useState('');
    const [dateSignHrApprover, savedateSignHrApprover] = useState('');

	const [FinanceApprover, saveFinanceApprover] = useState('');
	const [FinanceApproverTitle, saveFinanceApproverTitle] = useState('');
    const [signatureFinanceApprover, saveSignatureFinanceApprover] = useState('');
    const [dateSignFinanceApprover, savedateSignFinanceApprover] = useState('');

	const [AdditionalApprover, saveAdditionalApprover] = useState('');
	const [AdditionalApproverTitle, saveAdditionalApproverTitle] = useState('');
    const [signatureAdditionalApprover, saveSignatureAdditionalApprover] = useState('');
    const [dateSignAdditionalApprover, savedateSignAdditionalApprover] = useState('');

    const timeBeginningRef = useRef('');
    const timeEndRef = useRef('');

	useEffect(()=> {

        const personalRequestDetail =  (request) => dispatch(getPersonalRequestDetail(id));
        personalRequestDetail();
		
		const validarFormulario = () => dispatch( validarFormularioAction());
		const exitoValidacion = () => dispatch( validacionExito() ) ;
    }, [dispatch, id])

	useEffect(() => {
		 if(Object.keys(personalRequest).length !== 0) {
		
			saveDateSubmitted(moment(personalRequest.date_submitted).format('MM-DD-YYYY'));
			savePositionTitle(personalRequest.position_title);
			saveDepartment(personalRequest.department);
			saveCostCenter(personalRequest.cost_center);
			saveStatus(personalRequest.status);
			saveRequestorName(personalRequest.requestor_name);
			saveRequestorTitle(personalRequest.requestor_job_title);
			saveSignatureEmployee(personalRequest.requestor_signature);
			saveDateSignEmployee(moment(personalRequest.date_requestor_signature).format('MM-DD-YYYY'));
			saveRequestedStartDate(moment(personalRequest.request_start_date).format('MM-DD-YYYY'));
			saveHiringManager(personalRequest.hiring_manager);
			saveContactNumber(personalRequest.contact_number);
			saveExempt(personalRequest.exempt);
			saveNonExempt(personalRequest.nonexempt);
			saveFullTime(personalRequest.fulltime);
			savePartTime(personalRequest.parttime);
			saveOther(personalRequest.other);
			saveOtherText(personalRequest.other_employee_classification);
			saveShiftFrom(moment(personalRequest.shiftFrom).format('HH:mm'));
			saveShiftTo(moment(personalRequest.shiftTo).format('HH:mm'));
			saveMonday(personalRequest.monday);
			saveTuesday(personalRequest.tuesday);
			saveWednesday(personalRequest.wednesday);
			saveThursday(personalRequest.thursday);
			saveFriday(personalRequest.friday);
			saveSaturday(personalRequest.saturday);
			saveSunday(personalRequest.sunday);
			saveadditionalCommentWorkSchedule(personalRequest.additional_comment_work_schedule);
			saveNewPosition(personalRequest.new_position);
			savePositionExist(personalRequest.position_exist);
			saveBudgeted(personalRequest.budgeted);
			saveExistingPositionReplacement(personalRequest.existing_position_replacement);
			saveDutiesChanged(personalRequest.duties_changed);
			savePositionInformation(personalRequest.position_information);
			saveDailyActivities(personalRequest.role_activities_objectives);
			saveMeasureProductivity(personalRequest.productivity_measure);
			saveFinancialImpact(personalRequest.financial_impact);
			savePositionFit(personalRequest.position_fit_structure);
			saveObjectives(personalRequest.objectives_risks_challenges_request);
			saveContingencies(personalRequest.contingences_position_not_approved);
			saveDateFilled(personalRequest.hr_date_filled);
			savePositionFilledBy(personalRequest.hr_position_filled_by);
			saveStartDate(personalRequest.hr_start_date);
			saveSalaryOffered(personalRequest.salary_offered);
			saveRecruitmentSource(personalRequest.recuitment_source);

			saveHrApprover(personalRequest.human_resources_approval_name);
			saveHrApproverTitle(personalRequest.human_resources_approval_job_title);
			saveSignatureHRApprover(personalRequest.human_resources_approval_signature);
			savedateSignHrApprover(moment(personalRequest.human_resources_approval_signature_date).format('MM-DD-YYYY'));

			saveFinanceApprover(personalRequest.finance_notification_request_name);
			saveFinanceApproverTitle(personalRequest.finance_notification_job_title);
			saveSignatureFinanceApprover(personalRequest.finance_notification_signature);
			savedateSignFinanceApprover(moment(personalRequest.finance_notification_signature_date).format('MM-DD-YYYY'));

			saveAdditionalApprover(personalRequest.additional_approval_name);
			saveAdditionalApproverTitle(personalRequest.additional_approval_job_title);
			saveSignatureAdditionalApprover(personalRequest.additional_approval_job_signature);
			savedateSignAdditionalApprover(moment(personalRequest.additional_approval_signature_date).format('MM-DD-YYYY'));
		}
	}, [personalRequest])



	const handleCheckbox = (value) => {
        //console.log(value);
        if(value === 'exempt') {
            saveExempt(!exempt);
        } else if(value === 'non-exempt') {
            saveNonExempt(!nonExempt);
        } else if(value === 'full-time') {
            saveFullTime(!fullTime);
        } else if(value === 'part-time') {
            savePartTime(!partTime);
        } else if(value === 'other') {
            saveOther(!other);
        } else if(value === 'monday') {
            saveMonday(!monday);
        } else if(value === 'tuesday') {
            saveTuesday(!tuesday);
        } else if(value === 'wednesday') {
            saveWednesday(!wednesday);
        } else if(value === 'thursday') {
            saveThursday(!thursday);
        } else if(value === 'friday') {
            saveFriday(!friday);
        } else if(value === 'saturday') {
            saveSaturday(!saturday);
        } else if(value === 'sunday') {
            saveSunday(!sunday);
        }else if (value === 'newPosition') {
            saveNewPosition(!newPosition);
            savePositionExist('no');
            saveExistingPositionReplacement('');
        }

    }


    return (
		<div className="row justify-content-center mt-5">
		<div className="col-md-8">
			<div className="card">
				<div className="card-body">
					<div className='row pt-3'>
						<div className='col-md-8'>
							<h2 className="text-center mb-4 font-weight-bold ">Recruitment Request Form</h2>
						</div>
						
						
						<div className="col-md-4 col-sm-12">
							<div className="form-group">
								<input 
									type="text" 
									className="form-control" 
									placeholder="Cost Center" 

									defaultValue={costCenter}
									//onChange={ e => saveCostCenter(e.target.value) }
									required
									disabled
								/>
							</div>
						</div>
					</div>
					<hr />
					<form>
						{/* Position Details */}
						<h3 className='form-title'>Position Details</h3>
						<div className="row justify-content-between">
							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Date Submitted</label>
									<input type='text' className='form-control' defaultValue={dateSubmitted} disabled/>
								</div>
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Position Title</label>
									<input 
										type="text" 
										className="form-control" 
										placeholder="" 
										//onChange={ e => savePositionTitle(e.target.value) }
										required
										defaultValue={positionTitle}
										disabled
									   
									/>
								</div>
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Department</label>
									<input type='text' className='form-control' defaultValue={department} disabled/>
								</div>
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Request Start Date</label>
									<input type='text' className='form-control' defaultValue={requestedStartDate} disabled/>
								</div>
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Hiring Manager</label>
									<input 
										type="text" 
										className="form-control" 
										placeholder="" 
										//onChange={ e => saveHiringManager(e.target.value) }
										required
										defaultValue={hiringManager}
										disabled
									/>
								</div>
							</div>    

							<div className="col-md-4">
								<div className="form-group">
									<label>Contact Number</label>
									<input
										type="text"
										className="form-control"
										defaultValue={contactNumber}
										required
										//onChange={e => saveContactNumber(e.target.value)}
										disabled
									/>
								</div>
							</div>
						
						</div>
					
						<hr />

						{/* Employee Clasification */}
						<div className="row justify-content-between">
							<div className="col-md-12 col-sm-12">
								<div className="form-group">
									<h3 className='d-block form-title'>Employee Classification</h3>
									<div className='row'> 
										{/* Begin Row */}
									<div className='col-md-6'>
										<div className="custom-control custom-switch custom-control-inline">
											<input 
												className="custom-control-input" id = "custom-switch-20"
												type="checkbox" 
												checked={exempt == 1 ? true : false}
												value={'exempt'} 
												readOnly
												//onChange={e => handleCheckbox(e.target.value)}
											/>
											<label className="custom-control-label" htmlFor="custom-switch-20">
												Exempt
											</label>
										</div>
										<div className="custom-control custom-switch custom-control-inline">
											<input 
												className="custom-control-input" id = "custom-switch-21"
												type="checkbox" 
												checked={nonExempt == 1 ? true : false} 
												value={'non-exempt'}
												//onChange={e => handleCheckbox(e.target.value)}
												readOnly
											/>
											<label className="custom-control-label" htmlFor="custom-switch-21">
												Non-Exempt
											</label>
										</div>
									</div>
									<div className='col-md-6'>
										<div className="custom-control custom-switch custom-control-inline">
											<input 
												className="custom-control-input" id = "custom-switch-22"
												type="checkbox" 
												checked={fullTime == 1 ? true : false}
												value={'full-time'}
												// onChange={e => handleCheckbox(e.target.value)}
												readOnly
											/>
											<label className="custom-control-label" htmlFor="custom-switch-22">
												Full-Time
											</label>
										</div>
										<div className="custom-control custom-switch custom-control-inline">
											<input 
												className="custom-control-input" id = "custom-switch-23"
												type="checkbox" 
												checked={partTime == 1 ? true : false} 
												value={'part-time'}
												//onChange={e => handleCheckbox(e.target.value)}
												readOnly
											/>
											<label className="custom-control-label" htmlFor="custom-switch-23">
												Part-Time
											</label>
										</div>
										<div className="custom-control custom-switch custom-control-inline">
											<input 
												className="custom-control-input" id = "custom-switch-24"
												type="checkbox" 
												checked={other == 1 ? true : false} 
												value={'other'}
												//onChange={e => handleCheckbox(e.target.value)}
												readOnly
											/>
											<label className="custom-control-label" htmlFor="custom-switch-24">
												Other
											</label>
											
										</div>

										{ other ? 
												(
												<div className="form-group mt-2 w-100">
													<input 
														type="text" 
														className="form-control" 
														placeholder="Please specify" 
														//onChange={e => saveOtherText(e.target.value)} 
														required 
														defaultValue={otherText}
														disabled
													/>
												</div>
											)
											:null
										}
									</div>
									{/* End Row */}
									</div>
								</div>
							</div>
						</div>
					  
						<hr />

						{/* Work Schedule */}
						<h3 className='form-title'>Work Schedule</h3>
						<div className="row justify-content-between">
							
							<div className="col-md-8 col-sm-12">
								<div className="form-group">
									<label>Shift From:</label>
									<input 
										type="text" 
										className="form-control" 
										//onChange={e => saveShiftFrom(e.target.value)} 
										required
										defaultValue={shiftFrom}
										disabled 
									/>
									
									<label>Shift To:</label>
									<input 
										type="text" 
										className="form-control" 
										//onChange={e => saveShiftTo(e.target.value)} 
										defaultValue={shiftTo}
										required 
										disabled
									/>
									
								</div>
							</div>
							<div className="col-md-4 col-sm-12">
								<div className="form-group">
								   
									<div className="custom-control custom-switch custom-control-inline">
										<input 
											className="custom-control-input" id = "custom-switch-31"
											type="checkbox" 
											value={'monday'}
											checked={monday == 1 ? true : false}
											//onChange={e => handleCheckbox(e.target.value)}
											readOnly
										/>
										<label className="custom-control-label" htmlFor="custom-switch-31">
											Monday
										</label>
									</div>
									<div className="custom-control custom-switch custom-control-inline">
										<input 
											className="custom-control-input" id = "custom-switch-32"
											type="checkbox" 
											value={'tuesday'}
											checked={tuesday == 1 ? true : false}
											//onChange={e => handleCheckbox(e.target.value)}
											readOnly
										/>
										<label className="custom-control-label" htmlFor="custom-switch-32">
											Tuesday
										</label>
									</div>
									<div className="custom-control custom-switch custom-control-inline">
										<input 
											className="custom-control-input" id = "custom-switch-33"
											type="checkbox" 
											value={'wednesday'}
											checked={wednesday == 1 ? true : false}
											//onChange={e => handleCheckbox(e.target.value)}
											readOnly
										/>
										<label className="custom-control-label" htmlFor="custom-switch-33">
											Wednesday
										</label>
									</div>
									<div className="custom-control custom-switch custom-control-inline">
										<input 
											className="custom-control-input" id = "custom-switch-34"
											type="checkbox" 
											value={'thursday'}
											checked={thursday == 1 ? true : false}
											//onChange={e => handleCheckbox(e.target.value)}
											readOnly
										/>
										<label className="custom-control-label" htmlFor="custom-switch-34">
											Thursday
										</label>
									</div>
									<div className="custom-control custom-switch custom-control-inline">
										<input 
											className="custom-control-input" id = "custom-switch-35"
											type="checkbox" 
											value={'friday'}
											checked={friday == 1 ? true : false}
											//onChange={e => handleCheckbox(e.target.value)}
											readOnly
										/>
										<label className="custom-control-label" htmlFor="custom-switch-35">
											Friday
										</label>
									</div>
									<div className="custom-control custom-switch custom-control-inline">
										<input 
											className="custom-control-input" id = "custom-switch-36"
											type="checkbox" 
											value={'saturday'}
											checked={saturday == 1 ? true : false}
											//onChange={e => handleCheckbox(e.target.value)}
											readOnly
										/>
										<label className="custom-control-label" htmlFor="custom-switch-36">
											Saturday
										</label>
									</div>
									<div className="custom-control custom-switch custom-control-inline">
										<input
											className="custom-control-input" id = "custom-switch-37"
											type="checkbox" 
											value={'sunday'}
											checked={sunday == 1 ? true : false}
											//onChange={e => handleCheckbox(e.target.value)}
											readOnly
										/>
										<label className="custom-control-label" htmlFor="custom-switch-37">
											Sunday
										</label>
									</div>
								</div>
							</div>
							<div className='col-md-12'>
								<div className="form-group">
									<label>Additional Comments on Work Schedule:</label>
									<textarea 
											className="form-control"
											defaultValue={additionalCommentWorkSchedule}
											//onChange={e=>saveadditionalCommentWorkSchedule(e.target.value)}
											//onChange = {handleChangeInput}
											required
											disabled
											>
									</textarea>
							</div>
							</div>
						</div>

						<hr />
						{/* Position Information */}
						<h3 className='form-title'>Position Information</h3>
						<div className="row justify-content-between">
								<div className="col-md-4 col-sm-12">
									<div className="form-group">
										<div className="custom-control custom-switch custom-control-inline">
											<input 
												className="custom-control-input" id="custom-switch-25"
												type="checkbox" 
												checked={newPosition == 1 ? true : false} 
												value="newPosition"
												//onChange={e => handleCheckbox(e.target.value)}
												readOnly
											/>
											<label className="custom-control-label" htmlFor="custom-switch-25">
												New Position
											</label>
										</div>

										
									</div>
								</div>

								<div className="col-md-4 col-sm-12">
									<div className="form-group">
										<label>Does this position exist now in your team?</label>
										<select 
											className="form-control" 
											defaultValue={positionExist === 1 ? 'yes' : 'no'} 
											//onChange={e => savePositionExist(e.target.value)} 
											required
											disabled = {newPosition === true ? true : false} 
										>
											
											<option value="yes">Yes</option>
											<option value="no">No</option>
											
										</select>
									</div>

									<div className="form-group">
										<label>Budgeted</label>
										<select 
											className="form-control" 
											defaultValue={budgeted === 1 ? 'yes' : 'no'} 
											//onChange={e => saveBudgeted(e.target.value)} 
											required
										>
											
											<option value="yes">Yes</option>
											<option value="no">No</option>
										</select>
									</div>
								</div>

								<div className="col-md-4 col-sm-12">
									<div className="form-group">
										<label>Existing Position Replacement for (name)</label>
										<input 
											type="text" 
											className="form-control" 
											placeholder="Enter name"
											defaultValue={existingPositionReplacement} 
											//onChange={e => saveExistingPositionReplacement(e.target.value)} 
											required
											disabled = {newPosition === true ? true : false} 
										/>
									</div>
								</div>

								<div className="col-md-12">
									<div className="form-group">
										<label>Have the duties of this position changed?</label>
										<select 
											className="form-control" 
											defaultValue={dutiesChanged === 1 ? 'yes' : 'no'} 
											//onChange={e => saveDutiesChanged(e.target.value)} 
											required
											disabled
										>
											
											<option value="yes">Yes</option>
											<option value="no">No</option>
										</select>
										
									</div>

									<div className="form-group">
										<label>Position Information: (complete only if no job description exists or the position has changed)</label>
										<textarea
											className="form-control"
											defaultValue={positionInformation}
											//onChange={e=>savePositionInformation(e.target.value)}
											//onChange = {handleChangeInput}
											required
											disabled
											>
										</textarea>
									</div>

									<div className="form-group">
										<label>What daily activities/objectives does the role address?</label>
										<textarea
											className="form-control"
											defaultValue={dailyActivities}
											onChange={e=>saveDailyActivities(e.target.value)}
											//onChange = {handleChangeInput}
											required
											disabled
											>
										</textarea>
									</div>

									<div className="form-group">
										<label>How do you measure productivity? (volume of activity)</label>
										<textarea
											className="form-control"
											defaultValue={measureProductivity}
											onChange={e=>saveMeasureProductivity(e.target.value)}
											//onChange = {handleChangeInput}
											required
											disabled
											>
										</textarea>
									</div>

									<div className="form-group">
										<label>Explain the financial impact of this position (cost vs. ROI, cost savings, revenue expected)</label>
										<textarea
											className="form-control"
											defaultValue={financialImpact}
											onChange={e=>saveFinancialImpact(e.target.value)}
											//onChange = {handleChangeInput}
											required
											disabled
											>
										</textarea>
									</div>

									<div className="form-group">
										<label>Explain how this position will fit into the current team structure?</label>
										<textarea
											className="form-control"
											defaultValue={positionFit}
											//onChange={e=>savePositionFit(e.target.value)}
											//</div></div>onChange = {handleChangeInput}
											required
											disabled
											>
										</textarea>
									</div>

									<div className="form-group">
										<label>What objectives, risks, or business challenges are driving this request?</label>
										<textarea
											className="form-control"
											defaultValue={objectives}
											//onChange={e=>saveObjectives(e.target.value)}
											//onChange = {handleChangeInput}
											required
											disabled
											>
										</textarea>
									</div>

									<div className="form-group">
										<label>What contingencies exist if the position is not approved?</label>
										<textarea
											className="form-control"
											defaultValue={contingencies}
											//onChange={e=>saveContingencies(e.target.value)}
											//onChange = {handleChangeInput}
											required
											disabled
											>
										</textarea>
									</div>

									
								</div>
						</div>

						<hr />
						{/*  Approvals && Notifications */}

						<h3 className='d-block form-title'>Approvals & Notifications</h3>
						<div className="row container justify-content-between">
							
						<div className="col-md-8 col-sm-12">
							 
								<div className="form-group">
								<label>Requestor Name:</label>
									<input
									type="text"
									className="form-control"
									defaultValue={requestorName}
									required
									//onChange={e => saveRequestorName(e.target.value)}
									disabled
									/>
									
								</div>
							   
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Requestor Title:</label>
									<input 
										type="text" 
										className="form-control" 
										defaultValue={requestorTitle}
										required
										//onChange={e => saveRequestorTitle(e.target.value)}
										disabled
									/>
								</div>
							</div>

							
							<div className="col-md-8 col-sm-12">
							
								<div clasname="form-group">
								<label style={{fontSize:'1.4rem'}}>Requestor Signature:</label>
									<div className="signatureContainer" style={{width:'100%'}}>
									
									<img width={100} height={100} src={signatureEmployee} alt="signature"/>
										{/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
										
									</div>
								</div>
								{/* <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button> */}
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Date:</label>
									<input 
									type="text" 
									className="form-control"
									defaultValue={dateSignEmployee}
									//onChange={e=>saveDateSignEmployee(e.target.value)}
									disabled
									/>
									
								</div>
							</div>
							
						</div>

						{personalRequest.status === 'H.R. Approval' || personalRequest.status === 'Completed'
						
						?
						<Fragment>
						<hr />
						<div className="row container justify-content-between">
							
						<div className="col-md-8 col-sm-12">
							 
								<div className="form-group">
								<label>Human Resources Approval Name:</label>
									<input
									type="text"
									className="form-control"
									defaultValue={HrApprover}
									required
									//onChange={e => saveRequestorName(e.target.value)}
									disabled
									/>
									
								</div>
							   
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Human Resources Approval Title:</label>
									<input 
										type="text" 
										className="form-control" 
										defaultValue={HrApproverTitle}
										required
										//onChange={e => saveRequestorTitle(e.target.value)}
										disabled
									/>
								</div>
							</div>

							
							<div className="col-md-8 col-sm-12">
							
								<div clasname="form-group">
								<label style={{fontSize:'1.4rem'}}>Human Resources Approval Signature:</label>
									<div className="signatureContainer" style={{width:'100%'}}>
									
									<img width={100} height={100} src={signatureHRApprover} alt="signature"/>
										{/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
										
									</div>
								</div>
								{/* <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button> */}
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Date:</label>
									<input 
									type="text" 
									className="form-control"
									defaultValue={dateSignHrApprover}
									//onChange={e=>saveDateSignEmployee(e.target.value)}
									disabled
									/>
									
								</div>
							</div>
							
						</div>
						</Fragment>
						:
						null
					
					}

					{personalRequest.status === 'Finance Approval' || personalRequest.status === 'Completed'
						
						?
						<Fragment>
						<hr />
						<div className="row container justify-content-between">
							
						<div className="col-md-8 col-sm-12">
							 
								<div className="form-group">
								<label>Finance Approver Name:</label>
									<input
									type="text"
									className="form-control"
									defaultValue={FinanceApprover}
									required
									//onChange={e => saveRequestorName(e.target.value)}
									disabled
									/>
									
								</div>
							   
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Finance Approver Title:</label>
									<input 
										type="text" 
										className="form-control" 
										defaultValue={FinanceApproverTitle}
										required
										//onChange={e => saveRequestorTitle(e.target.value)}
										disabled
									/>
								</div>
							</div>

							
							<div className="col-md-8 col-sm-12">
							
								<div clasname="form-group">
								<label style={{fontSize:'1.4rem'}}>Finance Approver Signature:</label>
									<div className="signatureContainer" style={{width:'100%'}}>
									
									<img width={100} height={100} src={signatureFinanceApprover} alt="signature"/>
										{/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
										
									</div>
								</div>
								{/* <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button> */}
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Date:</label>
									<input 
									type="text" 
									className="form-control"
									defaultValue={dateSignFinanceApprover}
									//onChange={e=>saveDateSignEmployee(e.target.value)}
									disabled
									/>
									
								</div>
							</div>
							
						</div>
						</Fragment>
						:
						null
					
					}

					{personalRequest.status === 'Additional Approval' || personalRequest.status === 'Completed'
						
						?
						<Fragment>
						<hr />
						<div className="row container justify-content-between">
							
						<div className="col-md-8 col-sm-12">
							 
								<div className="form-group">
								<label>Additional Approver Name:</label>
									<input
									type="text"
									className="form-control"
									defaultValue={AdditionalApprover}
									required
									//onChange={e => saveRequestorName(e.target.value)}
									disabled
									/>
									
								</div>
							   
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Additional Approver Title:</label>
									<input 
										type="text" 
										className="form-control" 
										defaultValue={AdditionalApproverTitle}
										required
										//onChange={e => saveRequestorTitle(e.target.value)}
										disabled
									/>
								</div>
							</div>

							
							<div className="col-md-8 col-sm-12">
							
								<div clasname="form-group">
								<label style={{fontSize:'1.4rem'}}>Additional Approver Signature:</label>
									<div className="signatureContainer" style={{width:'100%'}}>
									
									<img width={100} height={100} src={signatureAdditionalApprover} alt="signature"/>
										{/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
										
									</div>
								</div>
								{/* <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button> */}
							</div>

							<div className="col-md-4 col-sm-12">
								<div className="form-group">
									<label>Date:</label>
									<input 
									type="text" 
									className="form-control"
									defaultValue={dateSignAdditionalApprover}
									//onChange={e=>saveDateSignEmployee(e.target.value)}
									disabled
									/>
									
								</div>
							</div>
							
						</div>
						</Fragment>
						:
						null
					
					}

					{userInfo.userInfo.email === 'nubia.osuna@mexilink.com' || userInfo.userInfo.email === 'anthony.marban@mexilink.com'

					?   
						<Fragment>
						<hr />
						{/*  Approvals && Notifications */}

						<h3 className='d-block form-title'>Human Resource Department Use Only</h3>
						<div className="row container justify-content-between">
							
							<div className="col-md-6 col-sm-12">
								<div className="form-group">
									<label>Date filled:</label>
									
									{/* <input 
									type="date" 
									className="form-control"
									onChange={e=>saveDateSignEmployee(e.target.value)}
									/> */}
									<DatePicker
									className="datePicker"
									todayButton="Today"
									selected={dateFilled}
									onChange={date => saveDateFilled(date)}
									
									placeholderText="Select a date"
									
									required
									
									/>
								</div>
							</div>

							<div className="col-md-6 col-sm-12">
								<div className="form-group">
									<label>Position Filled by:</label>
									<input 
										type="text" 
										className="form-control" 
										value={positionFilledBy}
										required
										onChange={e => savePositionFilledBy(e.target.value)}
									/>
								</div>
							</div>

							<div className="col-md-6 col-sm-12">
								<div className="form-group">
									<label>Start Date:</label>
									<DatePicker
									className="datePicker"
									todayButton="Today"
									selected={startDate}
									onChange={date => saveStartDate(date)}
								   
									placeholderText="Select a date"
								   
									required
									
									/>
								</div>
							</div>

							<div className="col-md-6 col-sm-12">
								<div className="form-group">
									<label>Salary Offered:</label>
									<input 
										type="text" 
										className="form-control" 
										value={salaryOffered}
										required
										onChange={e => saveSalaryOffered(e.target.value)}
									/>
								</div>
							</div>

							<div className="col-md-12 col-sm-12">
								<div className="form-group">
									<label>Recruitment Source:</label>
									<input 
										type="text" 
										className="form-control" 
										value={recruitmentSource}
										required
										onChange={e => saveRecruitmentSource(e.target.value)}
									/>
								</div>
							</div>

						</div>
						</Fragment>
					: null
						}


						{/* <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4 mt-4">Submit</button> */}
					
					</form>

					{ error ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please fill out all the required fields. </div> : null }
	
					</div>
				</div>
			</div>
		
	</div>
    );
};

export default ViewPersonalRequest;